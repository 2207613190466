<template>
  <div>
    <div class="station-heat-type">
      <Select v-model="queryModel.heatType" style="width:100px" size="small">
        <Option value="0">投放量</Option>
      </Select>
    </div>

    <div class="center-block-date">
      <Select v-model="queryModel.startDate" style="width:100px" size="small" @on-change="handleChangeDate">
        <Option v-for="(date,index) in startDateArray" :key="index" :value="date.key">{{date.value}}</Option>
      </Select>
      <span class="m-l-5 m-r-5">至</span>
      <Select v-model="queryModel.endDate" style="width:100px" size="small" @on-change="handleChangeDate">
        <Option v-for="(date,index) in endDateArray" :key="index" :value="date.key">{{date.value}}</Option>
      </Select>
    </div>

    <div id="heatMapHtml"></div>
  </div>
</template>

<script>
import { getStorage } from '@/utils/storage'
import { getStartDateList, getEndDateList } from '@/api/dw/time'
import { listResourceNumberGbStationName } from '@/api/dw/publisher'

export default {
  data () {
    return {
      mapUrl: '',
      amapObject: null, // 地图对象
      heatmapObject: null,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      publisherCityCode: this.$store.getters.token.userInfo.publishers[0].cityCode,
      mapCityCenter: [
        { cityCode: '350200', cityName: '厦门市', center: [118.103886046, 24.4892306125] },
        { cityCode: '370200', cityName: '青岛市', center: [120.383414, 36.075069] },
        { cityCode: '110100', cityName: '北京市', center: [116.395645038, 39.9299857781] },
        { cityCode: '330200', cityName: '宁波市', center: [121.579005973, 29.8852589659] },
        { cityCode: '510100', cityName: '成都市', center: [104.076673, 30.550755] },
        { cityCode: '530100', cityName: '昆明市', center: [102.722601, 25.014105] },
        { cityCode: '440600', cityName: '佛山市', center: [113.134025635, 23.0350948405] },
        { cityCode: '350100', cityName: '福州市', center: [119.330221107, 26.0471254966] }
      ],

      startDateArray: [],
      endDateArray: [],

      queryModel: {
        heatType: '0',
        endDate: '',
        startDate: ''
      }
    }
  },
  beforeDestroy () {
    this.amapObject && this.amapObject.destroy()
  },
  mounted () {
    if (this.themeIsDark) {
      // 路面媒体，如公交广告的mediaType为 2。 其余按照地铁来处理
      this.mapUrl = (this.$store.getters.token.publisherSetting.mediaType === 2)
        ? 'amap://styles/1f4dd7eedc2e98a369065bc005756706' : 'amap://styles/22479512d3a2c18a06bc6ec613e012c7'
    }

    // 设置地图宽高
    this.resizeMap()
    const that = this
    window.addEventListener('resize', function () {
      that.resizeMap()
    })

    this.queryModel.startDate = this.searchSchedule.startDate
    this.queryModel.endDate = this.searchSchedule.endDate
    this.initPageData()
  },
  methods: {
    initPageData () {
      const postData = { publisherId: this.publisherId }
      getStartDateList(postData).then(res => {
        this.startDateArray = res
      })
      getEndDateList(postData).then(res => {
        this.endDateArray = res
      })

      // 延迟加载，防止出现AMap not defined
      setTimeout(() => {
        this.initMapData()
      }, 1000)
    },
    initMapData () {
      // 获取地图中心点
      const center = this.mapCityCenter.find(x => x.cityCode === this.publisherCityCode).center
      this.loadMap(center)
      this.createHeatMap()
    },
    loadMap (center) {
      this.amapObject = new AMap.Map('heatMapHtml', {
        center: center,
        zoom: 12,
        mapStyle: this.mapUrl,
        zooms: [10, 16]
      })
    },
    resizeMap () {
      if (document.getElementById('heatMapHtml')) { // 防止切换界面，或者内容丢失后出错
        const centerBlock = document.getElementById('heatMapHtml').parentNode.parentNode.parentNode
        document.getElementById('heatMapHtml').style.width = centerBlock.clientWidth + 'px'
        document.getElementById('heatMapHtml').style.height = centerBlock.clientHeight + 'px'
      }
    },
    createHeatMap () {
      const __this = this
      this.amapObject.plugin(['AMap.HeatMap'], function () {
        // 初始化heatmap对象
        __this.heatmapObject = new AMap.HeatMap(__this.amapObject, {
          radius: 40 // 给定半径
          // gradient: {
          //   0.2: '#00FFFF',
          //   0.4: '#207cca',
          //   0.6: '#31ff00',
          //   0.8: '#f8ff00'
          //   // 0.95: '#ff0500'
          // }
        })
        __this.loadHeatMapData()
      })
    },
    loadHeatMapData () {
      const searchModel = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        packageIds: this.gbId
      }

      listResourceNumberGbStationName(searchModel).then(res => {
        let maxCount = 0
        const heatData = []
        res.forEach(item => {
          if (item.lng !== '') {
            if (maxCount < item.number) {
              maxCount = item.number
            }
            heatData.push({
              lng: parseFloat(item.lng),
              lat: parseFloat(item.lat),
              count: item.number
            })
          }
        })

        if (maxCount > 0) { // 防止无数据导致出现蓝色圆圈
          this.heatmapObject.setDataSet({
            data: heatData,
            max: maxCount
          })
        }
      })
    },
    handleChangeDate () {
      const schedule = {
        startDate: this.queryModel.startDate,
        endDate: this.queryModel.endDate
      }

      this.$store.commit('set_situation_searchSchedule', schedule)
      // 重新加载当前地图数据
      this.loadHeatMapData()
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    },
    themeIsDark () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark'
    }
  },
  watch: {
    gbId () {
      this.loadHeatMapData()
    }
  }
}
</script>

<style>
.station-heat-type{
  position: absolute;
  top: 50px;
  left: 20px;
  z-index: 9999;
}
</style>
