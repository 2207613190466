import request from '@/utils/requestV2'

// 统计签约额-按资源包
export function countAmountByPackage (data) {
  return request({
    url: '/ooh-dw/v1/datav/publisher/countAmountByPackage',
    method: 'get',
    params: data
  })
}
// 统计执行额并分组-按资源包
export function countExecuteAmountByPackage (data) {
  return request({
    url: '/ooh-dw/v1/datav/publisher/countExecuteAmountByPackage',
    method: 'get',
    params: data
  })
}
// 查询资源售卖的块天并分组-按资源包
export function countResourceSaleByPackage (data) {
  return request({
    url: '/ooh-dw/v1/datav/publisher/countResourceSaleByPackage',
    method: 'get',
    params: data
  })
}
// 查询当期登录业主的资源包列表
export function getPackageList (data) {
  return request({
    url: '/ooh-dw/v1/datav/publisher/getPackageList',
    method: 'get',
    params: data
  })
}
// 查询资源售卖的块天并分组-按资源包
export function listResourceNumberGbStationName (data) {
  return request({
    url: '/ooh-dw/v1/datav/publisher/listResourceNumberGbStationName',
    method: 'get',
    params: data
  })
}
